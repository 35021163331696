import * as React from 'react';
import '../styles/App.less';
import {Auth0} from '../components/Login/Auth0';
import Page from '../components/Layout/Page';

const IndexPage = () => {
  let connectionId;
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    connectionId = searchParams.get('connection') ?? '';
  }

  return (
    <Page title='Login'>
      <Auth0 connection={connectionId} />
    </Page>
  );
};

export default IndexPage;
